import Api from "../../Service/api";
import { T } from "react-translator-component";
import { useContext, useEffect, useState } from "react";
import { Form, message, Col, Select, Input } from "antd";
import { AutoShipContext, LoadingContext } from "../../App";
import { LeftOutlined, RightCircleOutlined } from '@ant-design/icons';

const api = new Api();
const { Option } = Select;

const ACHFile = ({refreshPaymentList , connector}) =>  {

    const [form] = Form.useForm();
    const [countryList, setCountryList] = useState();
    const { setLoading } = useContext(LoadingContext);
    const { autoShipDetails } = useContext(AutoShipContext);

    useEffect(() => {
        getCountryList();
        // eslint-disable-next-line
    },[]);

    const getCountryList = async () => {
        setLoading(true);
        let result = await api.mainRestCallService('external/country', 'GET');
        setLoading(false);
        if (result?.data) {
            setCountryList(result.data);
        } else if (result && result?.error?.code !== 401) {
            message.error(T('noCountryFound'));
        }
    }

    const getBankName = async (routingNumber) => {
        if (routingNumber) {
            setLoading(true);
            let result = await api.mainRestCallService('external/bank-routing-details/' + routingNumber, 'GET', null, false);
            if (result?.data) {
                form.setFieldsValue({ "bankName": result.data.bankName });
            } else if (result && result?.error?.code !== 401) {
                message.error(T('bnakNotFound'))
                form.setFieldsValue({ "bankName": null });
            }
        } else {
            form.setFieldsValue({ "bankName": null });
        }
        setLoading(false);
    };

    const onSubmit = async () => {
        setLoading(true)
        const field = form.getFieldsValue();
        const body = {
            "instrumentType": 1,
            "bankDetail": {
                "accountReference": field.accountReference,
                "accountName": field.accountName,
                "routingReference": field.routingReference,
                "accountType": field.accountType,
                "address": field.address,
                "city": field.city,
                "state": field.state,
                "zipcode": field.zipcode,
                "connectorId": connector,
                "identificationType": (field.identificationType) ? field.identificationType : '',
                "identificationValue": (field.identificationValue) ? field.identificationValue : '',
                "bicSwift": (field.bicSwift) ? field.bicSwift : '',
                'verificationType': 0,
                'currency': autoShipDetails?.currency,
                'userType': field.userType,
            },
            "requestReference": autoShipDetails?.requestReference,
        }

        let result = await api.mainRestCallService('consumers/payment-instrument', 'POST', body, false);
        if (result?.status === 1) {
            message.success(T('bankAddSuccess'));
            form.resetFields();
            refreshPaymentList();
        } else if (result && result?.error?.code !== 401) {
            if (result === 'error.duplicate.account.reference') {
                message.error(T('accountalreadyExists'));
            } else {
                message.error(T('bankAddFailed'));
            }
        }
        setLoading(false)
    }

    return (
        <div className="mt-4">
            <Form autoComplete="off" layout="vertical" style={{ width: '100%' }} form={form} onFinish={onSubmit}>
                <Col span={24}>
                    <Form.Item name="accountName" label={T('account_name')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                        rules={[
                            { required: true, message: T('invalidAccountName') },
                            { pattern: new RegExp("^[a-zA-Z !@#$,./?%^^&*()]+$"), message: T('invalidAccountName') },
                        ]}>
                        <Input key="ach-accountName" placeholder={T('account_name')} minLength={1} maxLength={45} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="accountReference" label={T('account_number')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                        rules={[
                            { required: true, message: T('accNum') },
                            { pattern: new RegExp("^[0-9]+$"), message: T('invalidAccountNumber') },
                            { max: 20, message: T('maxAccNum') },
                            { min: 4, message: T('minAccNum') }
                        ]}>
                        <Input key="ach-accountnumber" placeholder={T('account_number')} minLength={4} maxLength={20} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="routingReference" label={T('routing_number')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                        rules={[
                            { required: true, message: T('routingNum') },
                            { pattern: new RegExp("^[0-9]+$"), message: T('invalidRoutingNumber') },
                            { min: 9, message: T('minRoutingNum') }
                        ]}>
                        <Input key="add-routingnumber" placeholder={T('routing_number')} maxLength={9} onBlur={(e) => getBankName(e.target.value)} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="bankName" label={T('bankName')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                        rules={[{ required: true, message: ('invalidRoutingNumber') }]}>
                        <Input key="add-bankname" placeholder={T('bankName')} disabled />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="accountType" label={T('account_type')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} rules={[{ required: true, message: T('Acctype') }]}>
                        <Select key="add-account-type" placeholder={T('account_type')}>
                            <Option value="checking">{T('checking')}</Option>
                            <Option value="saving">{T('saving')}</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="userType" label={T('userType')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} rules={[{ required: true, message: T('usertypeErr') }]}>
                        <Select key="add-account-type" placeholder={T('userType')}>
                            <Option value="PERSONAL">{T('Personal')}</Option>
                            <Option value="CORPORATE">{T('Corporate')}</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="address" label={T('account_holder_address')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                        rules={[
                            { required: true, message: T('validAddress') },
                            { max: 400, message: T('maxAddress') },
                            { min: 5, message: T('minAddress') }
                        ]}>
                        <Input key="add-address" placeholder={T('account_holder_address')} minLength={5} maxLength={400} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="city" label={T('city')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                        rules={[
                            { required: true, message: T('cityRequired') },
                            { max: 60, message: T('maxCity') }
                        ]}>
                        <Input key="add-city" placeholder={T('city')} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="country" label={T('country')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} rules={[{ required: true, message: T('countryNameInvalid') }]}>
                        <Select showSearch key="add-country" optionFilterProp="children">
                            {countryList ? (
                                countryList.map(country => {
                                    return (
                                        <Option key={country.country_id} value={country.country_id}>
                                            {country.name}
                                        </Option>
                                    )
                                })) : null}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="state" label={T('state')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: T('stateNameinvalid')
                            },
                        ]}>
                        <Input key="state" placeholder={T('state')} minLength="2" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="zipcode" label={T('zipcode')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                pattern: new RegExp("^[0-9]+$"),
                                message: T('validzipCode')
                            }
                        ]}>
                        <Input key="postalcode" placeholder={T('zipcode')} minLength="5" maxLength="10" />
                    </Form.Item>
                </Col>

                <Col className="footer">
                    <button className="btn newCardbutton">
                        <LeftOutlined style={{ color: 'transparent' }} /> {T('submit_text')} <RightCircleOutlined />
                    </button>
                    <small style={{color: 'transparent'}}>{T('cancel_text')}</small>
                </Col>
            </Form>
        </div>
    );
};

export default ACHFile;