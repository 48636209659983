import Api from "../../Service/api";
import { message, Modal } from "antd";
import { T } from "react-translator-component";
import { useContext, useEffect, useState } from "react";
import { AutoShipContext, LoadingContext } from "../../App";

const api = new Api();

const RapydFile = ({connector , refreshPaymentList}) => {

    const [rapydInfo, setRapydInfo] = useState();
    const { setLoading } = useContext(LoadingContext);
    const { autoShipDetails } = useContext(AutoShipContext);
    const [ rapydModalStatus, setRapydModalStatus] = useState();

    useEffect(() => {
        getRapydCardToken();
        document.getElementsByClassName('listViewDiv')[0].classList.add('adjustHeight');
        return(() => document.getElementsByClassName('listViewDiv')[0]?.classList?.remove('adjustHeight'));
        // eslint-disable-next-line
    },[]);

    const getRapydCardToken = async () => {
        setLoading(true)
        const result = await api.mainRestCallService('external/cardToken?currency=' + autoShipDetails?.currency , 'GET');
        setLoading(false)
        if(result?.status === 1){
            loadRapydframe(result?.data);
        } else {
            message.error(T('wentWrongText'))
        }
    }

    const loadRapydframe = (rapydDetails) => {
        setLoading(true)
        window.renderJs({
            rapydToken: rapydDetails.token,
            url: rapydDetails.url,
        })
    };

    useEffect(() => {
        window.addEventListener("onLoading", loadingCheck);
        return () => window.removeEventListener("onLoading", loadingCheck);
        // eslint-disable-next-line
    },[]);

    const loadingCheck = (event) => {
        if(event.detail.loading === false){
            setLoading(false);
        }
    }

    //For Checking whether 3DS should go through or not----------
    useEffect(() => {
        window.addEventListener("onSaveCardDetailsSuccess", SaveCardDetailsSuccess);
        return () => {
            window.removeEventListener('onSaveCardDetailsSuccess', SaveCardDetailsSuccess);
        };
        // eslint-disable-next-line
    }, []);

    const SaveCardDetailsSuccess = function (event) {
        if (event.detail.next_action === "3d_verification") {
            setRapydInfo(event?.detail);
            setRapydModalStatus(true);
            window.removeEventListener('onSaveCardDetailsSuccess', SaveCardDetailsSuccess);
            return;
        }
        window.removeEventListener('onSaveCardDetailsSuccess', SaveCardDetailsSuccess);
        addCard(event.detail.payment_method_data);
    }
    // ----------

    //For Checking whether Card Added through or not----------
    useEffect(() => {
        window.addEventListener("onSaveCardDetailsFailure", SaveCardDetailsFailure);
        return () => window.removeEventListener("onSaveCardDetailsFailure", SaveCardDetailsFailure);
        // eslint-disable-next-line
    },[]);

    const SaveCardDetailsFailure = function (event) {
        message.error(T('cardAddFailText'));
    }
    // ---------

    //For Add Card If 3DS is success ----------
    useEffect(() => {
        window.addEventListener("message", RapydSuccess);
        return () => window.removeEventListener("message", RapydSuccess);
        // eslint-disable-next-line
    }, [rapydInfo]);

    const RapydSuccess = function (event) {
        if (event && event.data === 'rapyd_secure_success' && rapydInfo) {
            addCard(rapydInfo?.payment_method_data);
        }
    }
    // ----------


    const addCard = async (data) => {
        if (data) {
            setLoading(true)
            let url = 'external/payment-instrument';
            const body = {
                "instrumentType": 2,
                "cardDetail": {
                    "cardId": data?.id,
                    "connectorId": connector,
                    "last4": data?.last4,
                    "expire": data?.expiration_month + data?.expiration_year,
                    "cardType": data?.type,
                    "binNumber": data?.bin_details?.bin_number,
                    "cardHolderName": data?.name,
                    'currency': autoShipDetails?.currency
                },
                "requestReference": autoShipDetails?.requestReference,
            }
            var result = await api.mainRestCallService(url, 'POST', body);
            setLoading(false)
            if (result?.status === 1) {
                refreshPaymentList();
                message.success(T('cardAddSuccess'));
            } else {
                refreshPaymentList();
                message.error(T('cardAddFailText'));
            }
        } else {
            refreshPaymentList();
            message.error(T('cardAddFailText'));;
        }
    }

    return (
        <div className="">
            <div id="rapyd-toolkit"></div>

            <Modal open={rapydModalStatus} closable={false} centered footer={null} style={{width: '100vw !important', maxWidth: '640px'}}>
                <div style={{ display: 'flex', flexFlow: 'column wrap', alignItems: 'center' }}>
                    <iframe title="rapyd" src={rapydInfo?.redirect_url} style={{width: '100%', maxWidth: '640px' , height: '550px'}} />
                </div>
            </Modal>
        </div>
    )
}

export default RapydFile;