import Api from "../Service/api";
import PaymentAdd from "./PaymentAdd";
import PaymentSuccess from "./PaymentSuccess";
import PaymentFailure from "./PaymentFailure";
import { T } from "react-translator-component";
import { AutoShipContext, LoadingContext } from "../App";
import { Radio, Checkbox, Input, notification } from "antd";
import { AddCircleOutlineRounded } from "@material-ui/icons";
import React, { useState, useEffect, useContext } from "react";
import { currencyMapping, getCardImages } from "../app-utils/ExportData";
import { LeftOutlined, RightCircleOutlined } from '@ant-design/icons';

const api = new Api();

const PaymentList = () => {

    const [accounts, setAccounts] = useState();
    const [profileData, setProfileData] = useState();
    const [clientCredit, setClientCredit] = useState();
    const [addNewPayment, setAddNewPayment] = useState();
    const [cardEditDetails, setCardEditDetails] = useState();
    const [paymentConnectors, setPaymentConnectors] = useState();
    const [showConfirmScreen, setShowConfirmScreen] = useState();
    const [paymentInstruments, setPaymentInstruments] = useState();
    const [paymentInstrumentConnectors, setPaymentInstrumentConnectors] = useState();
    const [autoshipReqParams, setAutoshipReqParams] = useState({ "currency": null, "paymentInstrumentId": null, "requestReference": null, "paymentChannelId": null, "clientCreditId": null, "maxClientCredit": null, "fiatAccountId": null, "fiatMaxAmount": null, "loyaltyAccountId": null, "loyaltyMaxAmount": null });

    const { setLoading } = useContext(LoadingContext);
    const { autoShipDetails, setAutoShipDetails } = useContext(AutoShipContext);

    useEffect(() => {
        validateParams();
        setAutoshipReqParams((autoshipReqParams) => ({ ...autoshipReqParams, 'currency': autoShipDetails?.currency, 'requestReference': autoShipDetails?.requestReference }));
        // eslint-disable-next-line
    }, []);

    const setErrorMessages = (message) => {
        setAutoShipDetails((autoShipDetails) => ({ ...autoShipDetails, errorMessage: message }));
        setShowConfirmScreen('failure');
    }

    const validateParams = async () => {
        setLoading(true);
        const url = 'external/validate/checkout-params';
        const result = await api.mainRestCallService(url, 'POST', autoShipDetails);
        setLoading(false);
        if (result?.status) {
            getProfileData();
        } else {
            setErrorMessages(((result === 401) ? T('SessionExpired') : T('InvalidRequest')));
            return;
        }
    };

    const getProfileData = async () => {
        let result = await api.mainRestCallService('external/profile', 'GET');
        if (result && result.status === 1) {
            setProfileData((profileData) => ({ ...profileData, hkcm: result?.data?.hkcm, 'distributorId': result?.data?.distributorId}));
        }
    }

    useEffect(() => {
       if(profileData?.distributorId) {
            getAccounts();
            getClientCredit();
            getPaymentConnectors();
       }
       // eslint-disable-next-line
    },[profileData]);

    const getAccounts = async () => {
        setLoading(true);
        const url = 'external/accounts?status=1&currency=' + autoShipDetails?.currency;
        const result = await api.mainRestCallService(url, 'GET');
        setLoading(false);
        if (result?.status) {
            const list = result?.data.filter(data => (data.accountType !== 3 && data.accountType !== 5));
            setAccounts(list);
        } else if (result === 401) {
            setErrorMessages(T('SessionExpired'));
            return;
        } else {
            setAccounts();
        }
    }

    const getClientCredit = async () => {
        setLoading(true);
        const url = 'external/client-credit?currency=' + autoShipDetails?.currency;
        const result = await api.mainRestCallService(url, 'GET');
        setLoading(false);
        if (result?.status) {
            setClientCredit(result?.data);
        } else if (result === 401) {
            setErrorMessages(T('SessionExpired'));
            return;
        } else {
            setClientCredit([]);
        }
    };

    const getPaymentConnectors = async () => {
        setLoading(true);
        const url = 'external/connector?currency=' + autoShipDetails?.currency;
        const result = await api.mainRestCallService(url, 'GET');
        setLoading(false);
        if (result?.status) {
            var filteredPaymentConnectors = result?.data?.response.filter(data => (data.channel.id >= 7 && data.channel.id <= 11) || data.channel.id === 16);
            // 4902 changes =========================================================================
            if ((autoShipDetails?.currency === 'HKD' && profileData?.hkcm)) {
                filteredPaymentConnectors = filteredPaymentConnectors?.filter(data => (data?.channel?.id !== 7));
            }
            // 4902 changes =========================================================================
            var isAchConnector = result.data.response.find(connector => (connector.channel.id === 2));
            // MDAP-4467 ============================================================================
            if(isAchConnector) {
                const checkNmiAch = isAchConnector?.channel?.connectors.find(data => data.id === 13);
                if(!checkNmiAch) {
                    isAchConnector = null;
                }
            }
            // MDAP-4467 End=========================================================================
            const isCardConnectorPresent = result.data.response.find(connector => (connector.channel.id === 3));
            var isCardConnector = (isCardConnectorPresent) ? JSON.parse(JSON.stringify(isCardConnectorPresent)) : isCardConnectorPresent;
            // const CardConnectorwithoutWorldPay = isCardConnector?.channel?.connectors.find(connector => connector.id !== 16);
            if (isAchConnector || isCardConnector) {
                const type = ((isAchConnector && isCardConnector) ? '' : (isAchConnector) ? 1 : 2);
                getPaymentInstruments(type);
            }
            setPaymentInstrumentConnectors((isAchConnector && isCardConnector) ? [isCardConnector?.channel, isAchConnector?.channel] : (isAchConnector) ? [isAchConnector?.channel] : (isCardConnector) && [isCardConnector?.channel]);
            setPaymentConnectors((filteredPaymentConnectors) ? (filteredPaymentConnectors) : []);
        } else if (result === 401) {
            setErrorMessages(T('SessionExpired'));
            return;
        }
    };

    const getPaymentInstruments = async (type) => {
        setLoading(true);
        var url = 'external/payment-instrument?status=1&currency=' + autoShipDetails?.currency + '&instrument_type=' + (type);
        const result = await api.mainRestCallService(url, 'GET');
        setLoading(false);
        if (result?.status) {
            var paymentInstruments = result?.data.results;
            setPaymentInstruments(paymentInstruments);
        } else if (result === 401) {
            setErrorMessages(T('SessionExpired'));
            return;
        }
    };

    const refreshPaymentList = () => {
        const isAchConnector = paymentInstrumentConnectors.find(connector => (connector.id === 2));
        const isCardConnector = paymentInstrumentConnectors.find(connector => (connector.id === 3));
        if (isAchConnector || isCardConnector) {
            const type = ((isAchConnector && isCardConnector) ? '' : (isAchConnector) ? 1 : 2);
            getPaymentInstruments(type);
        }
        setCardEditDetails();
        setAddNewPayment(false);
    }

    // onSelecting Radio for payment Channel and payment Instrument -------------------
    const onPaymentChange = (value, type) => {
        if (type === 'paymentInst') {
            const connectorId = paymentInstruments.find(payment => payment.payment_instrument_id === value)?.connectorId;
            setAutoshipReqParams((autoshipReqParams) => ({ ...autoshipReqParams, 'paymentInstrumentId': value, 'paymentChannelId': null, 'connectorId': connectorId }));
        } else if (type === 'paymentChannel') {
            setAutoshipReqParams((autoshipReqParams) => ({ ...autoshipReqParams, 'paymentInstrumentId': null, 'paymentChannelId': value }))
        }
    }

    // onSelecting or delecting checkbox for credit, uwallet and uwallet funding -------
    const onWalletChange = (value, type, event) => {
        if (event) {
            setAutoshipReqParams((autoshipReqParams) => ({ ...autoshipReqParams, [type]: (event.target.checked) ? value : null }))
        } else {
            setAutoshipReqParams((autoshipReqParams) => ({ ...autoshipReqParams, [type]: (value) ? (value) : null }))
        }
    }

    const getAvailableBalance = (type) => {
        const account = accounts.find(data => data?.accountType === type);
        return account?.available_balance;
    }

    const submitAutoShipConfig = async () => {
        if ((autoshipReqParams?.clientCreditId && !autoshipReqParams?.maxClientCredit) || (!(/^\d+(\.\d{1,2})?$/.test(autoshipReqParams?.maxClientCredit)) && autoshipReqParams?.maxClientCredit) || (autoshipReqParams?.maxClientCredit > clientCredit.points)) {
            notification.error({ message: 'Error', description: T(['validCreditPoints']) });
            return;
        }
        if ((autoshipReqParams?.fiatAccountId && !autoshipReqParams?.fiatMaxAmount) || (!(/^\d+(\.\d{1,2})?$/.test(autoshipReqParams?.fiatMaxAmount)) && autoshipReqParams?.fiatMaxAmount) || (autoshipReqParams?.fiatMaxAmount > await getAvailableBalance(1))) {
            notification.error({ message: 'Error', description: T('uwalletAmountCheckText') });
            return;
        }
        if ((autoshipReqParams?.loyaltyAccountId && !autoshipReqParams?.loyaltyMaxAmount) || (!(/^\d+(\.\d{1,2})?$/.test(autoshipReqParams?.loyaltyMaxAmount)) && autoshipReqParams?.loyaltyMaxAmount) || (autoshipReqParams?.loyaltyMaxAmount > await getAvailableBalance(2))) {
            notification.error({ message: 'Error', description: T('uwalletFundAmountCheckText') });
            return;
        }

        if (!autoshipReqParams?.clientCreditId && !autoshipReqParams?.fiatAccountId && !autoshipReqParams?.loyaltyAccountId && !autoshipReqParams?.paymentInstrumentId && !autoshipReqParams?.paymentChannelId) {
            notification.error({ message: 'Error', description: T('paymentMethodErrorText') });
            return;
        }
        // For world Pay channels -------------------
        if (!autoshipReqParams?.paymentInstrumentId) {
            const selectedChannel = paymentConnectors.find(channel => channel?.channel?.id === autoshipReqParams?.paymentChannelId)?.channel;
            const connectorId = selectedChannel?.connectors[0].id;
            autoshipReqParams['connectorId'] = connectorId;
            autoshipReqParams['paymentChannelId'] = (selectedChannel?.duplicateChannel) ? (selectedChannel?.originalChannelId) : autoshipReqParams['paymentChannelId'];
        }
        // For world Pay channels -------------------
        const url = 'external/autoship-order-config';
        const result = await api.mainRestCallService(url, 'POST', autoshipReqParams);
        if (result?.status) {
            setShowConfirmScreen('success');
        } else {
            setErrorMessages(((result === 401) ? T('SessionExpired') : T('autoshipFailTxt')));
            return;
        }
    }

    const redirectToMerchant = () => {
        var url = (autoShipDetails?.redirectUrl) + ((autoShipDetails?.redirectUrl.indexOf('?') >= 0) ? '&' : '?') + 'status=CANCELLED';
        window.open(url, "_self");
    }

    const isPaymentMethodAvailable = () => {
        const isAvailable = [];
        paymentInstrumentConnectors?.forEach(data => {
            if (data?.connectors?.length > 0) {
                isAvailable.push(data);
            }
        });
        if (isAvailable.length > 0) {
            return true;
        }
    }

    const editCard = (paymentObj) => {
        setCardEditDetails(paymentObj);
        setAddNewPayment(true);
    }

    return (
        <>
            {
                (!showConfirmScreen && !addNewPayment) &&
                <div className="">
                    <div className="">
                        <div className={'d-flex align-items-center justify-content-between mt-4'}>
                            <h6 className="mb-0"><b>{T('payment_instrument_list')}</b></h6>
                        </div>

                        {
                            (clientCredit) &&
                            <div className="cardOverlay mt-2">
                                <div className="d-flex align-items-center justify-content-between h35">
                                    <div>
                                        <h6 className="letterSpace_2 mb-0" style={{ fontSize: '12px', lineHeight: 1.7 }}> {T('credit_points')} </h6>
                                        <small>{T('current_balance')}: {currencyMapping(autoShipDetails?.currency)}{clientCredit?.points?.toFixed(2) ?? '0.00'}</small>
                                    </div>
                                    <div> <Checkbox checked={autoshipReqParams?.clientCreditId} onChange={($event) => onWalletChange(clientCredit?.clientCreditId, 'clientCreditId', $event)} /> </div>
                                </div>
                                {
                                    (autoshipReqParams?.clientCreditId) &&
                                    <div className="mt-2">
                                        <Input value={autoshipReqParams?.maxClientCredit} onInput={($event) => onWalletChange($event.target.value, 'maxClientCredit', null)} placeholder={T('maxCreditPoint')} />
                                        {
                                            ((!(/^\d+(\.\d{1,2})?$/.test(autoshipReqParams?.maxClientCredit)) && autoshipReqParams?.maxClientCredit) || (autoshipReqParams?.maxClientCredit > clientCredit.points)) &&
                                            <small className="input-label-error">{T('validCreditPoints')}</small>
                                        }
                                    </div>
                                }
                            </div>
                        }

                        {
                            accounts?.map((account, index) => (
                                <div key={index} className="cardOverlay mt-2">
                                    <div className="d-flex align-items-center justify-content-between h35">
                                        <div>
                                            <h6 className="letterSpace_2 mb-0" style={{ fontSize: '12px', lineHeight: 1.7 }}> {(account?.accountType === 1) ? T('uwallet') : T('uwalletFund')} </h6>
                                            <small>{T('current_balance')}: {currencyMapping(autoShipDetails?.currency)}{account?.available_balance.toFixed(2) ?? '0.00'}</small>
                                        </div>
                                        {(account?.accountType === 1) && <div> <Checkbox checked={autoshipReqParams?.fiatAccountId} onChange={($event) => onWalletChange(account?.account_id, 'fiatAccountId', $event)} /> </div>}
                                        {(account?.accountType === 2) && <div> <Checkbox checked={autoshipReqParams?.loyaltyAccountId} onChange={($event) => onWalletChange(account?.account_id, 'loyaltyAccountId', $event)} /> </div>}
                                    </div>
                                    {
                                        (account?.accountType === 1 && autoshipReqParams?.fiatAccountId) &&
                                        <div className="mt-2">
                                            <Input value={autoshipReqParams?.fiatMaxAmount} onInput={($event) => onWalletChange($event.target.value, 'fiatMaxAmount', null)} placeholder={T('max_amount')} />
                                            {
                                                ((!(/^\d+(\.\d{1,2})?$/.test(autoshipReqParams?.fiatMaxAmount)) && autoshipReqParams?.fiatMaxAmount) || (autoshipReqParams?.fiatMaxAmount > account?.available_balance)) &&
                                                <small className="input-label-error">{T('validAmountText')}</small>
                                            }
                                        </div>
                                    }
                                    {
                                        (account?.accountType === 2 && autoshipReqParams?.loyaltyAccountId) &&
                                        <div className="mt-2">
                                            <Input value={autoshipReqParams?.loyaltyMaxAmount} onInput={($event) => onWalletChange($event.target.value, 'loyaltyMaxAmount', null)} placeholder={T('max_amount')} />
                                            {
                                                ((!(/^\d+(\.\d{1,2})?$/.test(autoshipReqParams?.loyaltyMaxAmount)) && autoshipReqParams?.loyaltyMaxAmount) || (autoshipReqParams?.loyaltyMaxAmount > account?.available_balance)) &&
                                                <small className="input-label-error">{T('validAmountText')}</small>
                                            }
                                        </div>
                                    }
                                </div>
                            ))
                        }

                        {
                            (paymentConnectors?.length > 0 || paymentInstruments?.length > 0) &&
                            <div className={'d-flex align-items-center justify-content-between mt-4'}>
                                <h6 className="mb-0"><b>{T('payment_instruments')}</b></h6>
                                <h6 className="mb-0 link" onClick={() => onPaymentChange(null, 'paymentInst')}><b>{T('reset_text')}</b></h6>
                            </div>
                        }

                        {
                            (paymentInstruments?.length > 0 || paymentConnectors?.length > 0) &&
                            <Radio.Group value={(autoshipReqParams?.paymentChannelId) ?? (autoshipReqParams?.paymentInstrumentId)}>
                                {
                                    (paymentInstruments?.length > 0) && paymentInstruments.map((instmnt, index) =>
                                        <div className="mt-2" key={index}>
                                            <div className="cardOverlay">
                                                <div className="d-flex align-items-center justify-content-between h35">
                                                    <div className="d-flex align-items-center">
                                                        {(instmnt?.instrumentType === 2) && <img className={"card_img"} src={getCardImages(instmnt?.cardType)} alt={instmnt.cardType} />}
                                                        {(instmnt?.instrumentType === 1) && <img className={"card_img"} src={'https://images.vexels.com/media/users/3/263264/isolated/preview/11a26a1102ca38d370338cf6a91459c4-money-business-bank-icon.png'} alt={'Bank'} />}
                                                        <div className="nametext">
                                                            <h6 className="letterSpace_2 mb-0" style={{ fontSize: '12px', lineHeight: 1.7 }}>
                                                                <span>{instmnt?.accountName}</span>
                                                                { (instmnt?.connectorId === 16 && instmnt?.status === 1) && <span onClick={() => editCard(instmnt)} className="pl-2 text-primary" style={{cursor: 'pointer'}}>{"Edit"}</span> }
                                                                <br></br>
                                                                {(instmnt?.instrumentType === 2) && 
                                                                    <>
                                                                        {'**** **** **** ' + instmnt.cardNumberLast4}
                                                                        <small> ({`${instmnt.expMonth}/${instmnt.expYear}`})</small>
                                                                    </> 
                                                                }
                                                                {(instmnt?.instrumentType === 1) && '****' + instmnt.account_reference.slice(-4)}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Radio name="paymentInst" onClick={() => onPaymentChange(instmnt.payment_instrument_id, 'paymentInst')} value={instmnt.payment_instrument_id}></Radio>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    (paymentConnectors?.length > 0) && paymentConnectors.map((connector, index) =>
                                        <div className="mt-2" key={index}>
                                            <div className="cardOverlay">
                                                <div className="d-flex align-items-center justify-content-between h35">
                                                    <div className="d-flex align-items-center">
                                                        <img className={"card_img"} src={'https://www.svgrepo.com/download/593/money.svg'} alt={connector?.channel.displayName} />
                                                        <div className="nametext">
                                                            <h6 className="letterSpace_2 mb-0" style={{ fontSize: '12px', lineHeight: 1.7 }}>
                                                                {((T(connector?.channel.displayName.replace(/_/g, '').toLowerCase())[0]) === (connector?.channel.displayName.replace(/_/g, '').toLowerCase())) ? (connector?.channel.displayName.replace(/_/g, ' ')) : (T(connector?.channel.displayName.replace(/_/g, '').toLowerCase()))}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Radio onClick={() => onPaymentChange(connector?.channel?.id, 'paymentChannel')} value={connector?.channel?.id}></Radio>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </Radio.Group>
                        }

                        {
                            (isPaymentMethodAvailable()) &&
                            <div className="cardOverlay mt-2" style={{ cursor: 'pointer' }}>
                                <div className="d-flex align-items-center justify-content-center p-1" onClick={() => setAddNewPayment(true)}>
                                    <AddCircleOutlineRounded /><h6 className="letterSpace_2 mb-0 pl-2" style={{ fontSize: '12px' }}>{T('bank_card_add')}</h6>
                                </div>
                            </div>
                        }

                        <div className="footer">
                            <button onClick={() => submitAutoShipConfig()} className="btn newCardbutton"> <LeftOutlined style={{ color: 'transparent' }} /> {T('submit_text')} <RightCircleOutlined /></button>
                            <small className="link" onClick={() => redirectToMerchant()}>{T('cancel_text')}</small>
                        </div>
                    </div>
                </div>
            }

            {(showConfirmScreen === 'success') && <PaymentSuccess />}

            {(showConfirmScreen === 'failure') && <PaymentFailure />}

            {(addNewPayment) && <PaymentAdd availableConnectors={paymentInstrumentConnectors} cardEditDetails={cardEditDetails} refreshPaymentList={refreshPaymentList} />}
        </>
    )
}

export default PaymentList;
