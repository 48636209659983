module.exports = {
    "bank_card_list": "Payment Methods",
    "bank_card_add": "Add New Payment Methods",
    "payment_instrument_list": "Saved Payment Methods",
    "cancel_text": "Cancel",
    "submit_text": "Submit",
    "not_found_text": "No Payment Method Found",
    "card_holder_name": "Card Holder Name",
    "card_number": "Card Number",
    "expiry_date": "Expiration Date",
    "cvv": "CVV",
    "card_text": "Card",
    "bank_text": "Bank",
    "save_card": "Save Card",
    "save_bank": "Save Bank Account",
    "account_name": "Account Name",
    "account_number": "Account Number",
    "routing_number": "Routing Number",
    "account_type": "Account Type",
    "select": "Select",
    "account_holder_address": "Account Holder Address",
    "city": "City",
    "state": "State",
    "country": "Country",
    "zipcode": "Zip Code",
    "payment_instruments": "Payment Instruments",
    "reset_text": "Reset",
    "credit_points": "Credit Points",
    "current_balance": "Current Balance",
    "uwallet": "UWallet",
    "uwalletFund": "UWallet Funding",
    "validCreditPoints": "Please enter valid Credit Points",
    "maxCreditPoint": "Maximum Credit Points",
    "max_amount": "Maximum Amount",
    "validAmountText": "Please enter valid amount",
    "SessionExpired": "Session Expired",
    "InvalidRequest": "Invalid Request",
    "uwalletAmountCheckText": "Please enter valid UWallet amount",
    "uwalletFundAmountCheckText": "Please enter valid UWallet Funding amount",
    "paymentMethodErrorText": "Please select any payment method",
    "autoshipFailTxt": "Failed to create autoship!",
    "wentWrongText": "Something went wrong, Please try again later.",
    "cardAddFailText": "Failed to add card",
    "cardAddSuccess": "Card Added Successfully",
    "bankPageLoadFail": "Failed to Load Bank add Page!.",
    "bankAddFailed": "Failed to add Bank",
    "bankAddSuccess": "Bank added Successfully",
    "accountNamePlaceholder": "Please enter account name",
    "accountNumberPlaceholder": "Please enter account number",
    "routingNumberPlaceholder": "Please enter routing number",
    "invalidAccountName": "Please enter valid account name",
    "invalidAccountNumber": "Please enter valid account number",
    "invalidRoutingNumber": "Please enter valid routing number",
    "cardAddPageFail": "Failed to Load Card add Page!.",
    "cardName": "Name on Card",
    "cardNameNull": "Card Name required",
    "plsEnterValidName": "Please enter valid name",
    "InvalidCardNumber": "Invalid Card Number",
    "InvalidExpirationDate": "Invalid Expiration Date",
    "InvalidCVV": "Invalid CVV",
    "accountalreadyExists": "Account already Exists",
    "bnakNotFound": "Bank Not Found",
    "maxAccNum": "Account number cannot be more than 20 digits.",
    "minAccNum": "Account number cannot be less than 4 digits.",
    "accNumber": "Please enter the Account Number.",
    "minRoutingNumber": "Routing number cannot be less than 9 digits.",
    "routingNum": "Please enter the Routing Number.",
    "bankName": "Bank Name",
    "Acctype": "Please select an Account Type.",
    "checking": "Checking",
    "saving": "Saving",
    "userType": "User Type",
    "usertypeErr": "Please select an User Type.",
    "validzipCode": "Please enter a valid Zip Code",
    "stateNameinvalid": "Please enter a valid State",
    "validAddress": "Please enter a valid address.",
    "maxAddress": "Address cannot be more than 400 Characters.",
    "minAddress": "Address cannot be less than 5 Characters",
    "cityRequired": "Please enter your city.",
    "maxCity": "City cannot be more than 60 Characters.",
    "countryNameInvalid": "Please Select a Country Name",
    "selectPayment": "Select Payment Method",
    "back": "Back",
    "cardType": "Card Type",
    "ccintl": "Credit Card International",
    "amex_dis": "Amex/Discover",
    "amex_card_text": "Add AMEX Card",
    "mater_visa": "MasterCard/Visa",
    "loadingText": "Loading...,please wait!",
    "add_card_text": "Add Credit Card",
    "credit_card": "Credit Card",
    "cardUpdatedSuccessfully": "Card updated successfully",
    "reason": "Reason",
    "failedUpdateCard": "Failed to update card",
    "requiredError": "This field is required",
}