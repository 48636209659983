import axios from 'axios';

var isRefreshing = false;
var ApiOptions = {
    method: '',
    body: '',
    headers: ''
};

export default class Api {

  constructor() {
    this.api_url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : window.location.origin + '/';
  }

  /**************************************************************************************
   ** mainRestCallService
   ** @param {*} apiPath : api end point
   ** @param {*} method : POST, PUT, DELETE, GET
   ** @param {*} body : request body if not send goes as null
   ** @param {*} asBody : By default true, if false goes as url params.
   ** @returns
   *************************************************************************************/
   mainRestCallService = async (apiPath, method, body = null) => {
    var options = ApiOptions;
    var url = this.api_url + apiPath;
    options.method = method;

    var result;
    if(method === 'POST'){
      result = await this.postMethod(url , body)
    } else if (method === 'GET'){
      result = await this.getMethod(url)
    }

    if(result === 'session_expired'){
      return 401;
    } else if (result) {
      return result ?? 'Unsuccessful response';
    } else {
      return result;
    }
  }


  // get Method
  getMethod = (url) => { return axios.get(url); }

  // Post Method
  postMethod = (url , body) => { return axios.post(url , body); }

}

axios.interceptors.request.use(async config => {
    var token = sessionStorage.getItem('token');;
    if (token) { config.headers["Authorization"] = `Token ${token}`; }
    return config;
  },
  error => { return Promise.reject(error);}
);

axios.interceptors.response.use(response => {
  if (response?.data !== 'session_expired') {
    return response?.data;
  }
},
  async function (error) {
    const originalConfig = error.config;
    if (error.response?.status === 401 && isRefreshing === false && ((originalConfig?.url?.pathname?.indexOf('/login') < 0) || (originalConfig?.url?.indexOf('/login') < 0))) {
      isRefreshing = true;
      originalConfig._retry = true;
      return 'session_expired';
    };
    return error.response?.data;
  }
);
