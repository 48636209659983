import Api from "../Service/api";
import NMIFile from "./NewPaymentMethods/NMI";
import ACHFile from "./NewPaymentMethods/Ach";
import { T } from "react-translator-component";
import RapydFile from "./NewPaymentMethods/Rapyd";
import WorldPay from "./NewPaymentMethods/WorldPay";
import NmiAchFile from "./NewPaymentMethods/NMIACH";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useContext, useState, useEffect } from "react";
import { AutoShipContext, LoadingContext } from "../App";
import { Form, Col, Row, Select, message, Input } from "antd";
import { LeftOutlined, RightCircleOutlined } from '@ant-design/icons';

const api = new Api();
const { Option } = Select

const PaymentAdd = ({ availableConnectors, refreshPaymentList, cardEditDetails }) => {

    const [editForm] = Form.useForm();
    const [selectedConnector, setSelectedConnector] = useState();
    const [selectedPaymentChannel, setSelectedPaymentInstChannel] = useState();

    const { setLoading } = useContext(LoadingContext);
    const { autoShipDetails } = useContext(AutoShipContext);

    useEffect(() => {
        if(cardEditDetails?.payment_instrument_id) {
            return
        }
        setSelectedPaymentInstChannel(availableConnectors[0]);
        updateSelectedChannel(availableConnectors[0])
        // eslint-disable-next-line
    }, []);

    const isMultiplePaymentMethodAvailable = () => {
        const isAvailable = [];
        availableConnectors?.forEach(data => {
            if (data?.connectors?.length > 0) {
                isAvailable.push(data);
            }
        });
        if (isAvailable.length > 1) {
            return true;
        }
    }

    const updateSelectedChannel = (data) => {
        setSelectedPaymentInstChannel(data);
        if (data.id === 2 && autoShipDetails?.currency === 'USD') {
            setSelectedConnector(13);
        } else if (data.id === 2) {
            setSelectedConnector(data.connectors[0].id);
        }

        if (data.id === 3) {
            setSelectedConnector(data.connectors[0].id);
        }
    }

    const handleValueChange = (obj) => {
        if(obj.expiryDate) {
            var expFormatted;
            var expMonth = ((obj.expiryDate.split(' / ')[0]));
            var expYear = obj.expiryDate.split(' / ')[1];
            if (expYear) {
                expYear = obj.expiryDate.split(' / ')[1].trim()
            }
            if (expMonth) {
                expMonth = obj.expiryDate.split(' / ')[0].trim()
            }
            if (obj.expiryDate.length <= 8) {
                if (!expYear) {
                    if (expMonth > 1 && expMonth.length === 1) {
                        expMonth = '0'.concat(expMonth);
                    }
                    expFormatted = (expMonth.match(/.{1,2}/g)).join(' / ');
                } else {
                    expFormatted = expMonth.concat(' / ');
                    expFormatted = expFormatted.concat(expYear);
                }
            } else {
                expFormatted = expMonth.concat(' / ').concat(expYear.substring(0, 4));
            }
            editForm.setFieldsValue({ "expiryDate": expFormatted });
        }
    }

    const formatNumber = (number) => {
        return number.toString().padStart(2, '0');
    }

    const handleKeyPress = (event) => {
        const charCode = event.charCode;
        if (charCode < 48 || charCode > 57) {
          event.preventDefault();
        }
    };

    const onCardEditUpdate = async(data) => {
        let url = 'consumers/edit-worldpay-payment-instrument';
        const body = {
            "paymentInstrumentUuid" : cardEditDetails?.uuid,
            "expiryMonth" : data.expiryDate.split(' / ')[0],
            "expiryYear" : data.expiryDate.split(' / ')[1],
        }
        setLoading(true);
        var result = await api.mainRestCallService(url, 'POST', body);
        setLoading(false);
        if (result?.status === 1) {
            refreshPaymentList();
            message.success(T('cardUpdatedSuccessfully'));
        } else {
            message.error(T('failedUpdateCard'))
        }
    }

    return (
        <div className="mt-4">
            {
                (!cardEditDetails?.payment_instrument_id) &&
                <div className="">
                    {
                        ((isMultiplePaymentMethodAvailable)) &&
                        <>
                            <div className={'d-flex align-items-center justify-content-between'}>
                                <h5 className="mb-0"><b>{T('selectPayment')}</b></h5>
                                <p className="mb-0 link" onClick={() => refreshPaymentList()}><ArrowLeftOutlined /> {T('back')}</p>
                            </div>
                            <div className={'d-flex align-items-center justify-content-start mt-4'}>
                                {
                                    availableConnectors?.map(data => (
                                        <div key={data?.id} className={"buttonView " + ((selectedPaymentChannel?.id === data?.id) && 'buttonViewActive')}>
                                            <p onClick={() => updateSelectedChannel(data)} className="mb-0 text-center">
                                                {(data?.id === 3) ? T('card_text') : T('bank_text')}
                                            </p>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    }

                    {
                        (selectedPaymentChannel?.id === 3 && selectedPaymentChannel?.connectors?.length > 1) &&
                        <div className="mt-4 ">
                            <label className="mb-0">{T('cardType')}</label>
                            <Select className="form-control" onChange={($event) => setSelectedConnector($event)} value={selectedConnector} style={{ width: '100%' }} placeholder={T('select')}>
                                {
                                    selectedPaymentChannel?.connectors?.map(data => (
                                        <>
                                            {
                                                (data.id !== 16) ?
                                                    <Option key={data.index} value={data?.id}>
                                                        {(data.id === 2) ? ((autoShipDetails?.currency === "USD") ? T('ccintl') : T('mater_visa')) : ((autoShipDetails?.currency === "GBP" || autoShipDetails?.currency === "EUR") ? T('amex_card_text') : (autoShipDetails?.currency === 'USD') ? T('credit_card') : T('amex_dis'))}
                                                    </Option> :
                                                    <Option key={data.index} value={data.id}>
                                                        {T('add_card_text')} (WPay)
                                                    </Option>
                                            }
                                        </>
                                    ))
                                }
                            </Select>
                        </div>
                    }

                    {(selectedConnector === 2) && <RapydFile connector={selectedConnector} refreshPaymentList={refreshPaymentList} />}

                    {(selectedConnector === 4) && <NMIFile connector={selectedConnector} refreshPaymentList={refreshPaymentList} />}

                    {(selectedConnector === 13) && <NmiAchFile connector={selectedConnector} refreshPaymentList={refreshPaymentList} />}

                    {(selectedConnector === 16) && <WorldPay connector={selectedConnector} refreshPaymentList={refreshPaymentList} />}

                    {(selectedConnector === 1 && selectedPaymentChannel?.id === 2) && <ACHFile connector={selectedConnector} refreshPaymentList={refreshPaymentList} />}
                </div>
            }

            {
                (cardEditDetails?.payment_instrument_id) &&
                <div>
                    <Row className="mainRow" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="center">
                        <Form className="NMI" form={editForm} layout="vertical" style={{ width: '100%', marginTop: 24 }} onValuesChange={handleValueChange} onFinish={onCardEditUpdate} initialValues={{ expiryDate: `${formatNumber(cardEditDetails?.expMonth)} / ${cardEditDetails?.expYear}`, name: cardEditDetails?.accountName, number: `XXXX XXXX XXXX ${cardEditDetails?.cardNumberLast4}` }}>
                            <Col className="" span={24} style={{ marginBottom: 24 }}>
                                <Form.Item disabled={true} name="number" label={T('card_number')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                    rules={[
                                        { required: true, message: T('InvalidCardNumber') },
                                    ]}>
                                    <Input key="ach-accountnumber" placeholder={T('card_number')} minLength={3} disabled={true} />
                                </Form.Item>
                            </Col>

                            <Col className="" span={24} style={{ marginBottom: 24 }}>
                                <Form.Item name="expiryDate" label={T('expiry_date')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                    rules={[
                                        () => ({
                                            validator(_, value) {
                                                if (/^(0[1-9]|1[0-2]) \/ \d{4}$/.test(value)) {
                                                    const month = Number(value.split(' / ')[0]);
                                                    const year = Number(value.split(' / ')[1]);
                                                    if ((year > new Date().getFullYear()) || ((year === new Date().getFullYear()) && (month >= (new Date().getMonth() + 1)))) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error(T('InvalidExpirationDate')));
                                                }
                                                return Promise.reject(new Error(T('InvalidExpirationDate')));
                                            },
                                        }),
                                    ]}>
                                    <Input type="text" key="ach-accountnumber" placeholder={T('MM / YYYY')} onKeyPress={handleKeyPress} minLength={7} />
                                </Form.Item>
                            </Col>

                            <Col className="footer">
                                <button className="btn newCardbutton"> <LeftOutlined style={{ color: 'transparent' }} /> {T('submit_text')} <RightCircleOutlined /></button>
                                <small className="text-primary" style={{ fontSize: '16px', cursor: 'pointer' }} onClick={refreshPaymentList}>{T('cancel_text')}</small>
                            </Col>

                        </Form>

                    </Row>
                </div>
            }
        </div>
    )
}

export default PaymentAdd;