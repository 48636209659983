module.exports = {
    "bank_card_list": "支付方式",
    "bank_card_add": "新增支付方式",
    "payment_instrument_list": "已保存的支付方式",
    "cancel_text": "取消",
    "submit_text": "提交",
    "not_found_text": "未找到支付方式",
    "card_holder_name": "持卡人姓名",
    "card_number": "卡号",
    "expiry_date": "截止日期",
    "cvv": "CVV",
    "card_text": "卡片",
    "bank_text": "银行",
    "save_card": "保存卡片",
    "save_bank": "保存银行账户",
    "account_name": "账户名",
    "account_number": "账户号码",
    "routing_number": "路由号码",
    "account_type": "帐户类型",
    "select": "选择",
    "account_holder_address": "账户持有人地址",
    "city": "城市",
    "state": "州/省",
    "country": "国家/地区",
    "zipcode": "邮政编码",
    "payment_instruments": "支付工具",
    "reset_text": "重置",
    "credit_points": "學分",
    "current_balance": "當前餘額",
    "uwallet": "UWallet",
    "uwalletFund": "UWallet 资金",
    // new keyWords ------
    "validCreditPoints": "请输入有效积分",
    "maxCreditPoint": "最高学分",
    "max_amount": "最高金额",
    "validAmountText": "请输入有效金额",
    "SessionExpired": "会话过期",
    "InvalidRequest": "无效的请求",
    "uwalletAmountCheckText": "请输入有效的 UWallet 金额",
    "uwalletFundAmountCheckText": "请输入有效的 UWallet 资金金额",
    "paymentMethodErrorText": "请选择任何付款方式",
    "autoshipFailTxt": "无法创建自动送货！",
    "wentWrongText": "出了点问题",
    "cardAddFailText": "请稍后再试。",
    "cardAddSuccess": "添加卡失败",
    "bankPageLoadFail": "添加卡成功",
    "bankAddFailed": "无法加载银行添加页面！。",
    "bankAddSuccess": "添加银行失败",
    "accountNamePlaceholder": "银行添加成功",
    "accountNumberPlaceholder": "请输入账户名",
    "routingNumberPlaceholder": "请输入账号",
    "invalidAccountName": "请输入路由号码",
    "invalidAccountNumber": "请输入有效的账户名",
    "invalidRoutingNumber": "请输入有效帐号",
    "cardAddPageFail": "请输入有效的路由号码",
    "cardName": "未能加载卡添加页面！。",
    "cardNameNull": "卡片上的名字",
    "plsEnterValidName": "需要卡名",
    "InvalidCardNumber": "请输入有效名称",
    "InvalidExpirationDate": "无效卡号",
    "InvalidCVV": "失效日期无效",
    "accountalreadyExists": "无效的 CVV",
    "bnakNotFound": "用户名已经被注册",
    "maxAccNum": "找不到银行",
    "minAccNum": "帐号不能超过 20 位。",
    "accNumber": "账号不能少于4位。",
    "minRoutingNumber": "请输入帐号。",
    "routingNum": "路由号码不能少于 9 位。",
    "bankName": "请输入路由号码。",
    "Acctype": "银行名",
    "checking": "请选择账户类型。",
    "saving": "检查",
    "userType": "保存",
    "usertypeErr": "用户类型",
    "validAddress": "请选择用户类型。",
    "maxAddress": "请输入有效地址。",
    "minAddress": "地址不能超过 400 个字符。",
    "validzipCode": "地址不能少于5个字符",
    "stateNameinvalid": "请输入有效的邮政编码",
    "cityRequired": "请输入有效的州",
    "maxCity": "请输入您所在的城市。",
    "countryNameInvalid": "城市不能超过 60 个字符。",
    "selectPayment": "选择付款方式",
    "back": "银行",
    "cardType": "卡的种类",
    "ccintl": "国际信用卡",
    "amex_dis": "美国运通/探索卡",
    "amex_card_text": "添加美国运通卡",
    "mater_visa": "万事达卡/Visa卡",
    "loadingText": "加载请稍候！",
    "add_card_text": "添加信用卡",
    "credit_card": "信用卡",
    "cardUpdatedSuccessfully": "卡更新成功",
    "reason": "原因",
    "failedUpdateCard": "卡更新失败",
    "requiredError": "必填栏位"
}