import './App.css';
import Api from './Service/api';
import PaymentList from './Pages/PaymentList';
import NMIFile from './Pages/NewPaymentMethods/NMI';
import { createContext, useEffect, useState } from 'react';
import { Translator, LanguageList, Config, T } from 'react-translator-component'
import WorldPay from './Pages/NewPaymentMethods/WorldPay';

const api = new Api();
export const AutoShipContext = createContext(null);
export const LoadingContext = createContext(null);

function App() {

  const [lang, setLang] = useState('en');
  const [loading, setLoading] = useState();
  const [autoShipDetails, setAutoShipDetails] = useState();
  const [defaultConnectorJPY, setDefaultConnectorJPY] = useState();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const body = {
      language: params.get('lang'),
      currency: params.get('currency'),
      redirectUrl: params.get('redirect_url'),
      requestReference: params.get('request_reference'),
      type: Number(params.get('type')),
    };
    if(body?.type === 2) {
      getDefaultConnector(body?.currency);
    }
    sessionStorage.setItem('token', params.get('token'));
    setAutoShipDetails(body);
    setLanguageSpecific(body?.language);
  }, []);

  const getDefaultConnector = async(currency) => {
    setLoading(true);
    const url = `external/connector/${currency}`;
    const result = await api.mainRestCallService(url, 'GET');
    setLoading(false);
    if (result?.status) {
      setDefaultConnectorJPY(result.data.connectorId);
    }
  }

  const setLanguageSpecific = (fullLang) => {
    var search = fullLang?.split('-')[0]?.toLowerCase();
    var searchSuffix = fullLang?.split('-')[1]?.toLowerCase();
    if (search === 'en') {
      setLang('en');
      Config.default = 'en';
    } else if ((search === 'zh' && (searchSuffix === 'cn' || searchSuffix === 'hk')) || search === 'cn') {
      setLang('znch');
      Config.default = 'znch';
    } else if ((search === 'zh' && searchSuffix === 'tw')) {
      setLang('zhcht');
      Config.default = 'zhcht';
    } else if (search === 'ms') {
      setLang('ms');
      Config.default = 'ms';
    } else if (search === 'es') {
      setLang('es');
      Config.default = 'es';
    } else if (search === 'th') {
      setLang('th');
      Config.default = 'th';
    } else {
      setLang('en');
      Config.default = 'en';
    }
  }


  Config.list = {
    en: {
      text: "English",
      file: require("./assets/i18n/en")
    },
    znch: {
      text: "chinese(Simplified)",
      file: require("./assets/i18n/zn-ch")
    },
    zhcht: {
      text: "chinese(Traditional)",
      file: require("./assets/i18n/zh-CHT")
    },
    ms: {
      text: "Malay",
      file: require("./assets/i18n/ms")
    },
    es: {
      text: "Spanish",
      file: require("./assets/i18n/es")
    },
    th: {
      text: "Thai",
      file: require("./assets/i18n/th")
    },
  };

  return (
    <AutoShipContext.Provider value={{ autoShipDetails, setAutoShipDetails }}>
      {
        (autoShipDetails) &&
        <LoadingContext.Provider value={{ loading, setLoading }}>
          <Translator>
            <div>
              {
                (loading) &&
                <div className="loader_div">
                  <div className="text-center">
                    <img src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif" alt="loader" />
                    <p style={{ color: 'white', marginTop: 10 }}>{T('loadingText')}</p>
                  </div>
                </div>
              }
              <div className="listViewDiv">
                <div className="App bodyWidth">
                  <div className="">
                    <img src={'https://d2bibd1b1uy3k1.cloudfront.net/newulifeLogo_v1.png'} alt="logo" />
                  </div>
                  {(autoShipDetails?.type === 1) && <PaymentList />}
                  {(autoShipDetails?.type === 2 && defaultConnectorJPY === 4) && <NMIFile connector={4} /> }
                  {(autoShipDetails?.type === 2 && defaultConnectorJPY === 16) && <WorldPay connector={16} /> }
                </div>
              </div>
            </div>
            <LanguageList Language={lang} />
          </Translator>
        </LoadingContext.Provider>
      }
    </AutoShipContext.Provider>
  );
}

export default App;
