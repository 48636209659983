import Api from "../../Service/api";
import { Form, Col, message } from "antd";
import { T } from "react-translator-component";
import { useState, useContext, useEffect } from "react";
import { AutoShipContext, LoadingContext } from "../../App";
import { LeftOutlined, RightCircleOutlined } from '@ant-design/icons';

const api = new Api();

const NmiAchFile = ({ refreshPaymentList, connector }) => {

    const [form] = Form.useForm();
    const [aname, setAname] = useState(false);
    const [rnumber, setRnumber] = useState(false);
    const [anumber, setAnumber] = useState(false);
    const [nameInput, setNameInput] = useState(false);
    const [numberInput, setNumberInput] = useState(false);
    const [routingInput, setRoutingInput] = useState(false);

    const { setLoading } = useContext(LoadingContext);
    const { autoShipDetails } = useContext(AutoShipContext);

    useEffect(() => {
        getSaveCardKey();
        return (() => {
            const nmiScript = document.getElementById('nmiScript');
            document.head.removeChild(nmiScript);
        })
        // eslint-disable-next-line
    }, []);

    const getSaveCardKey = async () => {
        setLoading(true)
        const url = "external/apikey";
        let result = await api.mainRestCallService(url, 'GET');
        if (result?.status === 1) {
            if (!document.getElementById('nmiScript')) {
                const linkElement = document.createElement("script");
                linkElement.setAttribute('id', 'nmiScript')
                linkElement.src = 'https://secure.safewebservices.com/token/Collect.js';
                linkElement.setAttribute('data-tokenization-key', result.data.nmiPublicKey);
                document.head.appendChild(linkElement);
                linkElement.onload = function () {
                    loadNMIframe();
                    return;
                }
                linkElement.onerror = function () {
                    message.error(T('bankPageLoadFail'));
                    setLoading(false)
                    return;
                }
            } else {
                document.getElementById('nmiScript').setAttribute('data-tokenization-key', result.data.nmiPublicKey);
                loadNMIframe();
            }
        } else if (result && result?.error?.code !== 401) {
            setLoading(false)
            message.error(T('bankPageLoadFail'));
        }
    }

    const loadNMIframe = () => {
        if (window?.CollectJS) {
            const nmiJs = window?.CollectJS;
            nmiJs.inSubmission = false;
            window.CollectJS.configure({
                variant: 'inline',
                styleSniffer: true,
                paymentType: 'ck',
                fields: {
                    checkname: {
                        placeholder: T('accountNamePlaceholder')[0],
                        selector: '#checkname',
                    },
                    checkaccount: {
                        placeholder: T('accountNumberPlaceholder')[0],
                        selector: '#checkaccount'
                    },
                    checkaba: {
                        placeholder: T('routingNumberPlaceholder')[0],
                        selector: '#checkaba'
                    },
                },
                customCss: {
                    border: "none",
                    color: '#000000d9'
                },
                placeholderCss: {
                    color: '#c3c3c3'
                },
                validationCallback: (field, status, message) => {
                    validateFields(status, field, message)
                },
                callback: (token) => {
                    addBankAccount(token)
                },
                fieldsAvailableCallback: function () {
                    setLoading(false)
                },
            });
        } else {
            setTimeout(() => { loadNMIframe() }, 10)
        }
    }

    const addBankAccount = async (value) => {
        setLoading(true);
        let url = 'consumers/payment-instrument';
        const body = {
            "instrumentType": 1,
            "bankDetail": {
                "token": value.token,
                "connectorId": connector,
                "accountReference": value.check.account,
                "accountName": value.check.name,
                "routingReference": value.check.aba,
                'verificationType': 0,
                'currency': autoShipDetails?.currency
            },
            "requestReference": autoShipDetails?.requestReference,
        }
        var result = await api.mainRestCallService(url, 'POST', body);
        setLoading(false);
        if (result?.status === 1) {
            refreshPaymentList()
            message.success(T('bankAddSuccess'))
        } else {
            message.error(T('bankAddFailed'));
        }
    }

    const validateFields = (status, field, message) => {
        if (!status && field === 'checkname') {
            setAname(true);
            setNameInput(false);
        } else if (status && field === 'checkname') {
            setAname(false);
            setNameInput(true);
        }

        if (!status && field === 'checkaba') {
            setRnumber(true);
            setNumberInput(false);
        } else if (status && field === 'checkaba') {
            setRnumber(false);
            setNumberInput(true);
        }

        if (!status && field === 'checkaccount') {
            setAnumber(true);
            setRoutingInput(false);
        } else if (status && field === 'checkaccount') {
            setAnumber(false);
            setRoutingInput(true);
        }
        if (nameInput && numberInput && routingInput && !aname && !anumber && !rnumber) {
            document.getElementById('demoPayButton').click();
        }
        if ((!nameInput && aname) || (!numberInput && anumber) || (!routingInput && rnumber)) {
            setLoading(false);
        }
        const nmiJs = window?.CollectJS;
        nmiJs.inSubmission = false;
    }

    const onSubmit = () => {
        if (aname || anumber || rnumber) {
            return
        };
        if (nameInput && numberInput && routingInput) {
            setLoading(true);
            startRequest();
        };
    }

    const startRequest = () => {
        window.CollectJS.startPaymentRequest();
    }

    return (
        <div className="mt-4">
            <Form className="NMI" layout="vertical" style={{ width: '100%' }} initialValues={{ country: 1 }} form={form} onFinish={onSubmit}>

                <Col className="" span={24} style={{ marginBottom: 24 }}>
                    <label className="mb-0">{T('account_name')}</label>
                    <div id="checkname"></div>
                    {aname ? <p className="input-label-error position-absolute">{T('invalidAccountName')[0]}</p> : null}
                </Col>

                <Col className="" span={24} style={{ marginBottom: 24 }}>
                    <label className="mb-0">{T('account_number')}</label>
                    <div id="checkaccount"></div>
                    {anumber ? <p className="input-label-error position-absolute">{T('invalidAccountNumber')[0]}</p> : null}
                </Col>

                <Col className="" span={24} style={{ marginBottom: 24 }}>
                    <label className="mb-0">{T('routing_number')}</label>
                    <div id="checkaba"></div>
                    {rnumber ? <p className="input-label-error position-absolute">{T('invalidRoutingNumber')[0]}</p> : null}
                </Col>

                <Col hidden={true}><button id="demoPayButton" onClick={() => startRequest()} type="button">Pay the money.</button></Col>

                <Col className="footer">
                    <button className="btn newCardbutton" disabled={!nameInput || !numberInput || !routingInput}> <LeftOutlined style={{ color: 'transparent' }} /> {T('submit_text')} <RightCircleOutlined /></button>
                    <small style={{ color: 'transparent' }}>{T('cancel_text')}</small>
                </Col>

            </Form>
        </div>
    );
}

export default NmiAchFile;