import Api from "../../Service/api";
import { T } from "react-translator-component";
import { Form, Col, message, Input } from "antd";
import { useContext, useState, useEffect } from "react";
import { AutoShipContext, LoadingContext } from "../../App";
import { LeftOutlined, RightCircleOutlined } from '@ant-design/icons';


const api = new Api();

const NMIFile = ({ refreshPaymentList, connector }) => {

    const [form] = Form.useForm();
    const [CVV, setCVV] = useState(false);
    const [expiry, setExpiry] = useState(false);
    const [cardNumber, setCardNumber] = useState(false);
    const [cvvInput, setCvvInput] = useState(false);
    const [expiryInput, setExpiryInput] = useState(false);
    const [numberInput, setNumberInput] = useState(false);
    // const [buttonDisable, setButtonDisable] = useState(true);

    const { setLoading } = useContext(LoadingContext);
    const { autoShipDetails } = useContext(AutoShipContext);

    useEffect(() => {
        getSaveCardKey();
        return (() => {
            const nmiScript = document.getElementById('nmiScript');
            document.head.removeChild(nmiScript);
        })
        // eslint-disable-next-line
    }, []);

    const getSaveCardKey = async () => {
        setLoading(true);
        const url = "external/apikey";
        let result = await api.mainRestCallService(url, 'GET');
        if (result?.status === 1) {
            if (!document.getElementById('nmiScript')) {
                const linkElement = document.createElement("script");
                linkElement.setAttribute('id', 'nmiScript')
                linkElement.src = 'https://secure.safewebservices.com/token/Collect.js';
                linkElement.setAttribute('data-tokenization-key', result.data.nmiPublicKey);
                document.head.appendChild(linkElement);
                linkElement.onload = function () {
                    loadNMIframe();
                    return;
                }
                linkElement.onerror = function () {
                    message.error(T('cardAddPageFail'));
                    setLoading(false);
                    return;
                }
            } else {
                document.getElementById('nmiScript').setAttribute('data-tokenization-key', result.data.nmiPublicKey);
                loadNMIframe();
            }
        } else if (result && result?.error?.code !== 401) {
            setLoading(false);
            message.error(T('cardAddPageFail'))
        }
    }

    const loadNMIframe = () => {
        if (window?.CollectJS) {
            window.CollectJS.configure({
                variant: 'inline',
                styleSniffer: true,
                fields: {
                    ccnumber: {
                        placeholder: '0000 0000 0000 0000',
                        selector: '#ccnumber',
                    },
                    ccexp: {
                        placeholder: 'MM/YY',
                        selector: '#ccexp'
                    },
                    cvv: {
                        placeholder: '***',
                        selector: '#cvv'
                    },
                },
                customCss: {
                    border: "none",
                    color: '#000000d9'
                },
                placeholderCss: {
                    color: '#c3c3c3'
                },
                validationCallback: (field, status, message) => {
                    validateFields(status, field)
                },
                callback: (token) => {
                    addCard(token)
                },
                fieldsAvailableCallback: function () {
                    setLoading(false)
                },
            });
        } else {
            setTimeout(() => { loadNMIframe() }, 10)
        }
    }

    const addCard = async (value) => {
        setLoading(true);
        let url = 'external/payment-instrument';
        const body = {
            "instrumentType": 2,
            "cardDetail": {
                "cardId": value.token,
                "connectorId": connector,
                "last4": value.card.number.slice(-4),
                "expire": value.card.exp,
                "cardType": value.card.type,
                "binNumber": value?.card?.bin,
                "cardHolderName": form.getFieldValue('name'),
                "currency": autoShipDetails?.currency,
            },
            "requestReference": autoShipDetails?.requestReference,
        };
        var result = await api.mainRestCallService(url, 'POST', body);
        setLoading(false);
        if (result?.status === 1) {
            if (autoShipDetails?.type === 2 && !refreshPaymentList) {
                const redirectUrl = (autoShipDetails?.redirectUrl) + ((autoShipDetails?.redirectUrl.indexOf('?') >= 0) ? '&' : '?') + 'status=SUCCESS';
                window.open(redirectUrl, "_self");
                return;
            } else {
                message.success(T('cardAddSuccess'));
            }
        } else {
            if (autoShipDetails?.type === 2 && !refreshPaymentList) {
                const redirectUrl = (autoShipDetails?.redirectUrl) + ((autoShipDetails?.redirectUrl.indexOf('?') >= 0) ? '&' : '?') + 'status=FAILURE';
                window.open(redirectUrl, "_self");
                return;
            }
            message.error(T('cardAddFailText'));
        }
        refreshPaymentList();
    }

    const validateFields = (status, field) => {
        if (!status && field === 'ccnumber') {
            setNumberInput(false);
            setCardNumber(true);
        } else if (status && field === 'ccnumber') {
            setCardNumber(false);
            setNumberInput(true);
        }

        if (!status && field === 'ccexp') {
            setExpiry(true);
            setExpiryInput(false);
        } else if (status && field === 'ccexp') {
            setExpiry(false)
            setExpiryInput(true);
        }

        if (!status && field === 'cvv') {
            setCVV(true);
            setCvvInput(false);
        } else if (status && field === 'cvv') {
            setCVV(false);
            setCvvInput(true);
        }

        if(cvvInput && numberInput && expiryInput && !CVV && !cardNumber && !expiry) {
            document.getElementById('demoPayButton').click();
        }
        if((!cvvInput && CVV) || (!numberInput && cardNumber) || (!expiryInput && expiryInput) || (!status)) {
            setLoading(false);
        }
        const nmiJs = window?.CollectJS;
        nmiJs.inSubmission = true;
    }

    const onSubmit = () => {
        if (cardNumber || expiry || CVV || !form.getFieldValue('name')) {
            return
        }
        if(numberInput && cvvInput && expiryInput) {
            const nmiJs = window?.CollectJS;
            nmiJs.inSubmission = false;
            setLoading(true);
            startRequest();
        } else {
            setCVV((cvvInput) ? false : true);
            setExpiry((expiryInput) ? false : true);
            setCardNumber((numberInput) ? false : true);
            window?.closePaymentRequest();
        }
    }

    const startRequest = () => {
        window.CollectJS.startPaymentRequest();
    }

    const getManualFieldsValue = (event) => {
        const isFieldEmpty = Object.keys(form.getFieldsValue()).filter(element => !form.getFieldValue(element));
        const errorLength  = (form.getFieldsError().filter(({ errors }) => errors.length).length);
        if(isFieldEmpty?.length <= 0 || errorLength <= 0) {
            // setLoading(true);
            // startRequest();
        }
    }

    return (
        <div className="mt-4">
            <Form className="NMI" layout="vertical" style={{ width: '100%', marginTop: 24 }} initialValues={{ country: 1 }} form={form} onFieldsChange={getManualFieldsValue} onFinish={onSubmit}>

                <Col span={24} style={{ marginBottom: 24 }}>
                    <Form.Item name="name" label={T('cardName')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                        rules={[
                            { required: true, message: T('cardNameNull') },
                            { pattern: new RegExp("^[a-zA-Z !@#$,./?%^^&*()]+$"), message: T('plsEnterValidName') },
                            { min: 3, message: T('plsEnterValidName') }
                        ]}>
                        <Input key="ach-accountnumber" placeholder={T('cardName')} minLength={3} />
                    </Form.Item>
                </Col>

                <Col className="" span={24} style={{ marginBottom: 24 }}>
                    <label className="mb-0">{T('card_number')}</label>
                    <div id="ccnumber"></div>
                    {cardNumber ? <p className="input-label-error position-absolute">{T('InvalidCardNumber')[0]}</p> : null}
                </Col>

                <Col className="" span={24} style={{ marginBottom: 24 }}>
                    <label className="mb-0">{T('expiry_date')}</label>
                    <div id="ccexp"></div>
                    {expiry ? <p className="input-label-error position-absolute">{T('InvalidExpirationDate')[0]}</p> : null}
                </Col>

                <Col className="" span={24} style={{ marginBottom: 24 }}>
                    <label className="mb-0">{T('cvv')}</label>
                    <div id="cvv"></div>
                    {CVV ? <p className="input-label-error position-absolute">{T('InvalidCVV')[0]}</p> : null}
                </Col>

                <Col className="footer">
                    <button className="btn newCardbutton"> <LeftOutlined style={{ color: 'transparent' }} /> {T('submit_text')} <RightCircleOutlined /></button>
                    <small style={{ color: 'transparent' }}>{T('cancel_text')}</small>
                </Col>

            </Form>
        </div>
    );
};

export default NMIFile;