import { message } from "antd";
import Api from "../../Service/api";
import { T } from "react-translator-component";
import { useContext, useEffect, useState } from "react";
import { AutoShipContext, LoadingContext } from "../../App";

const api = new Api();

const WorldPay = ({ connector, refreshPaymentList }) => {

    const [cardAdded, setCardAdded] = useState(false);
    const { setLoading } = useContext(LoadingContext);
    const { autoShipDetails } = useContext(AutoShipContext);

    useEffect(() => {
        getWorldPayUrl();
        document.getElementsByClassName('listViewDiv')[0].classList.add('adjustHeight');
        return (() => document.getElementsByClassName('listViewDiv')[0]?.classList?.remove('adjustHeight'));
        // eslint-disable-next-line
    }, []);

    const getWorldPayUrl = async () => {
        setLoading(true);
        const result = await api.mainRestCallService('external/worldpay-payment-instrument/' + autoShipDetails?.currency, 'POST', {});
        setLoading(false);
        if (result?.status === 1) {
            window.loadWorldpay(result.data);
        } else {
            message.error('Something went wrong, Please try again later.')
        }
    }

    useEffect(() => {
        window.addEventListener("message", addCardStatus);
        return (() => window.removeEventListener("message", addCardStatus));
        // eslint-disable-next-line
    }, []);


    const addCardStatus = (event) => {
        if (event.data === 'load_funds_success') {
            window.destroy();
            setLoading(true);
            setCardAdded(true);
            if (autoShipDetails?.type === 2 && !refreshPaymentList) {
                const redirectUrl = (autoShipDetails?.redirectUrl) + ((autoShipDetails?.redirectUrl.indexOf('?') >= 0) ? '&' : '?') + 'status=SUCCESS';
                window.open(redirectUrl, "_self");
                return;
            } else {
                setTimeout(() => {
                    refreshPaymentList();
                    message.success(T('cardAddSuccess'));
                }, 30000);
            }
        } else if (event?.data === 'load_funds_failure') {
            window.destroy();
            setLoading(true);
            setCardAdded(true);
            if (autoShipDetails?.type === 2 && !refreshPaymentList) {
                const redirectUrl = (autoShipDetails?.redirectUrl) + ((autoShipDetails?.redirectUrl.indexOf('?') >= 0) ? '&' : '?') + 'status=FAILURE';
                window.open(redirectUrl, "_self");
                return;
            } else {
                setTimeout(() => {
                    refreshPaymentList();
                    message.error(T('cardAddFailText'));
                }, 5000);
            }
        }
    };


    return (
        <div className="mt-4">
            {(!cardAdded) && <div id='custom-html' style={{ width: '100%' }}></div>}
        </div>
    )
}

export default WorldPay;