import { useContext } from 'react';
import { useEffect } from 'react';
import { AutoShipContext } from '../App';
import SuccessImage from '../assets/checked.png';

const PaymentSuccess = () => {

    const { autoShipDetails } = useContext(AutoShipContext);

    useEffect(() => {
        setTimeout(() => {
            redirectToMerchant();
        },5000);
        // eslint-disable-next-line
    },[]);

    const redirectToMerchant = () => {
        var url = (autoShipDetails?.redirectUrl) + ((autoShipDetails?.redirectUrl.indexOf('?') >= 0) ? '&' : '?') + 'status=SUCCESS';
        window.open(url , "_self");
    }

    return (
        <div className="listViewDiv">
            <div className="bodyWidth">
                <div className="SuccessScreen">
                    <div>
                        <img className="" src={SuccessImage} alt="success" />
                        <h5>Autoship configuration added Successful!</h5>
                        <p className="reference">Transaction Reference: {autoShipDetails?.requestReference}</p>

                        <small onClick={() => redirectToMerchant()} className="link">Return to merchant</small>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentSuccess;