export const getCardImages = (type) => {
    if(type?.toLowerCase().indexOf('visa') >= 0) {
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Visa_Inc._logo.svg/2560px-Visa_Inc._logo.svg.png';
    } else if(type?.toLowerCase().indexOf('mastercard') >= 0) {
        return 'https://zeevector.com/wp-content/uploads/Mastercard-Emblem-PNG-VECTOR.png';
    } else if(type?.toLowerCase().indexOf('electron') >= 0) {
        return 'https://epayments.developer-ingenico.com/shared-images/content/logos/paymentproducts/pp_logo_122_v1.png';
    } else if(type?.toLowerCase().indexOf('maestro') >= 0) {
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fd/Maestro_logo.svg/2560px-Maestro_logo.svg.png';
    } else if(type?.toLowerCase().indexOf('amex') >= 0) {
        return 'https://d28wu8o6itv89t.cloudfront.net/images/amexlogopng-1578907833580.png';
    } else if(type?.toLowerCase().indexOf('diners') >= 0) {
        return 'https://icons.iconarchive.com/icons/designbolts/credit-card-payment/256/Diners-Club-International-icon.png';
    } else if(type?.toLowerCase().indexOf('discover') >= 0) {
        return 'https://1000logos.net/wp-content/uploads/2021/05/Discover-logo.png';
    } else if(type?.toLowerCase().indexOf('jcb') >= 0) {
        return 'https://cdn-icons-png.flaticon.com/512/196/196559.png';
    } else if(type?.toLowerCase().indexOf('unionpay') >= 0) {
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/UnionPay_logo.svg/2560px-UnionPay_logo.svg.png';
    } else {
        return 'https://cdn-icons-png.flaticon.com/512/5899/5899622.png';
    }
};

export const currencyMapping = (value) => {
    switch (value) {
        case 'USD':
            return '$';
        case 'CRC':
            return '₡';
        case 'TWD':
            return 'NT$';
        case 'SLP':
            return 'S';
        case 'HKD':
            return 'HKD$';
        case 'JPY':
            return '¥';
        case 'CNY':
            return 'CN¥';
        case 'THB':
            return '฿';
        case 'MYR':
            return 'RM';
        case 'SGD':
            return 'S$';
        case 'EUR':
            return '€';
        case 'GBP':
            return '£';
        case 'KRW':
            return '₩';
        case 'NGN':
            return '₦';
        case 'VND':
            return '₫';
        default:
            return '$';
    }
}
